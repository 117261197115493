.screen {
  display: grid;
  grid-template:
    'list list fileViewer' 1fr
    'fileLoader fileLoader fileViewer'
    'url url url' / minmax(200px, 1fr) minmax(200px, 1fr) minmax(300px, 2fr);
  padding: 40px;
  width: 100%;
  gap: 10px;
}

@media (max-width: 1000px) {
  .screen {
    height: 100%;
    width: 100%;
    padding: 20px;
    grid-template:
      'fileViewer fileViewer' auto
      'list list ' 1fr
      'fileLoader download'
      'url url' / 1fr 1fr;
  }
  .fileViewer {
    margin-top: 20px;
    max-height: 200px;
    /* display: none; */
  }
}

.fileViewer {
  grid-area: fileViewer;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-right: 10px;
}

.fileViewer::-webkit-scrollbar {
  background-color: var(--theme-bg-2);
  width: 3px;
}

.fileViewer::-webkit-scrollbar-thumb {
  background-color: var(--theme-highlight-1);
  border-radius: 10px;
  outline: none;
}

.lineItem {
  margin-bottom: 10px;
}

.lineItem > label {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.7em;
  margin-bottom: 10px;
  display: block;
}

.lineItem > p {
  background: rgba(var(--theme-bg-1-rgb), 0.4);
  padding: 10px 20px;
  border-radius: 5px;
  font-family: monospace;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lineItem > pre {
  background: rgba(var(--theme-bg-1-rgb), 0.4);
  padding: 10px 20px;
  border-radius: 5px;
  font-family: monospace;
  overflow: hidden;
  font-size: 0.7em;
}

.fileLoader {
  grid-area: fileLoader;
}
.downloadButton {
  grid-area: download;
}

.fileLoader,
.downloadButton {
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  gap: 20px;
  background-color: var(--theme-bg-3);
  border: none;
  color: var(--theme-color-1);
  cursor: pointer;
  padding: 15px 20px;
  text-transform: uppercase;
  justify-content: center;
}

.fileLoader:hover,
.downloadButton:hover {
  outline: none;
  opacity: 0.5;
}

.fileLoader:focus,
.downloadButton:focus {
  outline: 2px solid var(--theme-highlight-1);
  outline-offset: -1px;
  background-color: var(--theme-bg-2);
}

.url {
  grid-area: url;
  margin-bottom: -10px;
  display: flex;
  width: 100%;
  gap: 20px;
}

.url > div:first-child {
  flex-grow: 1;
}

.url .addBtn {
  color: var(--theme-color-1);
  margin-top: 31px;
  cursor: pointer;
  padding: 0;
}

.addBtn:hover,
.addBtn:focus {
  outline: none;
  color: var(--theme-highlight-1);
}
