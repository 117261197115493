.hotkeyContainer {
  padding: 10px;
  background-color: var(--theme-bg-1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.key {
  background: var(--theme-bg-2);
  padding: 10px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bolder;
  font-size: 0.8rem;
}

.description {
  display: inline-block;
  margin: 0;
}
