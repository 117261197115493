.modal {
  display: flex;
  border-radius: 10px;
  box-shadow: 0.3rem 0.3rem 0.6rem rgba(var(--theme-bg-1-rgb), 0.4) -0.2rem -0.2rem
    0.5rem rgba(var(--theme-bg-3-rgb), 0.3);
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  .modal {
    border-radius: 0;
  }
}

.config-actions-container {
  position: fixed;
  display: flex;
  bottom: 10px;
  right: 10px;
  color: var(--theme-color-1);
  gap: 20px;
}

/* .icon {
  cursor: pointer;
}

.icon:hover {
  color: var(--theme-highlight-1);
} */
